<template>
  <AppModal
    :click-to-close="true"
    :esc-to-close="true"
    name="account-settings"
    @closed="onClosed"
    @beforeOpen="onBeforeOpen"
    @close="$vfm.hide('account-settings')"
  >
    <div class="auth-modal">
      <h3 class="auth-modal__title">
        Account Settings
      </h3>
      <div class="auth-modal__body">
        <div class="auth-modal__tabs">
          <div
            class="auth-modal__tab"
            :class="{
              'auth-modal__tab--selected':
                selectedTab === 'profile-information',
            }"
            @click="selectedTab = 'profile-information'"
          >
            Profile Information
          </div>
          <div
            class="auth-modal__tab"
            :class="{
              'auth-modal__tab--selected':
                selectedTab === 'shipping-information',
            }"
            @click="selectedTab = 'shipping-information'"
          >
            Shipping Information
          </div>
          <div
            class="auth-modal__tab"
            :class="{
              'auth-modal__tab--selected': selectedTab === 'change-password',
            }"
            @click="selectedTab = 'change-password'"
          >
            Change Password
          </div>
        </div>
        <div class="auth-modal__content">
          <ProfileInformationForm
            v-if="selectedTab === 'profile-information' && user"
          />
          <ShippingInformationForm
            v-if="selectedTab === 'shipping-information' && user"
          />
          <ChangePasswordForm v-if="selectedTab === 'change-password'" />
        </div>
      </div>
    </div>
  </AppModal>
</template>

<script>
import { mapGetters } from 'vuex'

import AppModal from '@/core/components/modals/AppModal'

import ChangePasswordForm from '@/auth/components/forms/ChangePasswordForm'
import ProfileInformationForm from '@/auth/components/forms/ProfileInformationForm'
import ShippingInformationForm from '@/auth/components/forms/ShippingInformationForm'

export default {
  name: 'AccountSettingsModal',
  components: {
    AppModal,
    ChangePasswordForm,
    ProfileInformationForm,
    ShippingInformationForm,
  },
  data() {
    return {
      selectedTab: 'profile-information',
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
  },
  methods: {
    onBeforeOpen() {
      this.selectedTab = 'profile-information'
    },
    onClosed() {
      this.selectedTab = 'profile-information'
    },
  },
}
</script>
