export default {
  accessToken: '',
  accessTokenLifeTime: 0,
  accessTokenLastUpdate: null,

  refreshToken: '',
  refreshTokenLifeTime: 0,
  refreshTokenLastUpdate: null,

  user: null,
}
