<template>
  <AppModal
    name="reset-password"
    title="Reset Password"
    :small="true"
    :esc-to-close="true"
    @close="$vfm.hide('reset-password')"
    @closed="onClosed"
    @beforeOpen="onBeforeOpen"
  >
    <div>
      <h3 class="auth-modal__title">
        Reset Password
      </h3>
      <div v-if="!requestSent">
        <div class="u-margin-bottom-small">
          <InputPassword
            label="New Password"
            placeholder="New Password"
            :disabled="loading"
            v-model="newPassword"
          />
        </div>
        <div class="u-margin-bottom-medium">
          <InputText
            type="password"
            label="Repeat Password"
            placeholder="Repeat Password"
            :disabled="loading"
            :errors="validations.errors.confirmPassword"
            :show-errors="submitted"
            v-model="confirmPassword"
          />
        </div>
        <div v-if="errorMessage" class="u-flex-center u-margin-bottom-small">
          <p class="paragraph paragraph--red">
            {{ errorMessage }}
          </p>
        </div>
        <div>
          <ButtonSubmit :block="true" @click="resetPassword">
            Confirm password
          </ButtonSubmit>
        </div>
      </div>
      <div class="u-text-center u-margin-bottom-medium" v-else>
        <div class="u-margin-bottom-small">
          <p class="paragraph paragraph--grey">
            Password reset successfully. Please login
          </p>
        </div>
        <div class="u-margin-bottom-small">
          <div>
            <ButtonSubmit :block="true" @click="openLoginModal">
              Login
            </ButtonSubmit>
          </div>
        </div>
      </div>
    </div>
  </AppModal>
</template>

<script>
import {
  required,
  minLength,
  sameAs,
  minSymbolCount,
  noDuplicates,
} from '@/core/plugins/validator/validators'

import { sendResetPasswordRequest } from '@/api/accountApi'
import { getErrorMessage } from '@/core/helpers/error'

import AppModal from '@/core/components/modals/AppModal'
import ButtonSubmit from '@/core/components/buttons/ButtonSubmit'
import InputText from '@/core/components/inputs/InputText'
import InputPassword from '@/core/components/inputs/InputPassword'

const validators = {
  newPassword: [required(), minLength(12), minSymbolCount(1), noDuplicates()],
  confirmPassword: [required(), sameAs('newPassword')],
}

export default {
  name: 'ResetPasswordModal',
  components: {
    InputPassword,
    AppModal,
    ButtonSubmit,
    InputText,
  },
  data() {
    return {
      newPassword: '',
      confirmPassword: '',
      email: '',
      token: '',
      loading: false,
      submitted: false,
      requestSent: false,
      errorMessage: '',
    }
  },
  computed: {
    validations() {
      return this.$validator.validate(
        {
          newPassword: this.newPassword,
          confirmPassword: this.confirmPassword,
        },
        validators
      )
    },
  },
  methods: {
    onBeforeOpen(e) {
      if ('email' in e.ref.params.value) {
        this.email = e.ref.params.value.email
      }
      if ('token' in e.ref.params.value) {
        this.token = e.ref.params.value.token
      }
    },
    async resetPassword() {
      this.submitted = true
      this.errorMessage = ''

      if (!this.validations.isValid || this.loading) return

      try {
        this.loading = true

        const response = await sendResetPasswordRequest({
          email: this.email,
          token: this.token,
          password: this.newPassword,
        })
        if (response.data.processed) {
          this.requestSent = true
        }
      } catch (err) {
        this.errorMessage = getErrorMessage(err)
        setTimeout(() => {
          this.errorMessage = ''
        }, 5000)
      } finally {
        this.loading = false
      }
    },
    openLoginModal() {
      this.$vfm.hide('reset-password')
      this.$vfm.show('auth', {
        onSuccessLogin: async () => {
          await this.$router.push('/')
        },
      })
    },
    onClosed() {
      this.newPassword = ''
      this.confirmPassword = ''
      this.email = ''
      this.token = ''
      this.loading = false
      this.submitted = false
      this.requestSent = false
      this.errorMessage = ''
    },
  },
}
</script>
