<template>
  <div class="parts-table">
    <div class="parts-table__heading">
      <div class="parts-table__heading-item parts-table__heading-item--border">
        <span class="paragraph">
          {{ partsCountLabel }}
        </span>
      </div>
      <div class="parts-table__heading-item parts-table__heading-item--btn">
        <button class="parts-table__btn" @click="openBulkEditPartsModal">
          <i class="fas fa-pen" />
          Bulk Edit
        </button>
        <AppTooltip v-if="!selectedParts.length" position="top">
          Select one or more parts to use
        </AppTooltip>
      </div>
      <div class="parts-table__heading-item parts-table__heading-item--btn">
        <button
          class="parts-table__btn parts-table__btn--delete"
          @click="deleteSelectedParts"
        >
          <i class="fas fa-trash" />
          Delete
        </button>
        <AppTooltip v-if="!selectedParts.length" position="top">
          Select one or more parts to use
        </AppTooltip>
      </div>
    </div>
    <div class="parts-table__list">
      <div class="parts-table__head">
        <div
          class="parts-table__head-cell parts-table__head-cell--checkbox"
        ></div>
        <div class="parts-table__head-cell parts-table__head-cell--checkbox">
          <InputCheckbox
            :disabled="locked"
            :model-value="allPartsSelected"
            @update:modelValue="onToggleAllParts"
          />
        </div>
        <div class="parts-table__head-cell parts-table__head-cell--name">
          Name
        </div>
        <div class="parts-table__head-cell parts-table__head-cell--quantity">
          Quantity
        </div>
        <div class="parts-table__head-cell parts-table__head-cell--technology">
          Technology
        </div>

        <div
          class="parts-table__head-cell parts-table__head-cell--material parts-table__head-cell--no-margin-left"
        >
          Material
        </div>
        <div
          class="parts-table__head-cell parts-table__head-cell--finish parts-table__head-cell--no-margin-left"
        >
          Finish
        </div>
        <div class="parts-table__head-cell"></div>
      </div>
      <div class="parts-table__body">
        <PartRow
          v-for="part in parts"
          :technologies="technologies"
          :part="part"
          :key="part.original.id"
          @updatePart="$emit('updatePart', $event)"
          @deletePart="$emit('deletePart', part.id)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import pluralize from 'pluralize'

import PartRow from './PartRow'
import AppTooltip from '@/core/components/content/AppTooltip'
import InputCheckbox from '@/core/components/inputs/InputCheckbox'

export default {
  name: 'PartsTable',
  components: {
    PartRow,
    AppTooltip,
    InputCheckbox,
  },
  props: {
    parts: {
      type: Array,
      default: () => [],
    },
    technologies: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    selectedParts() {
      return this.parts.filter(part => part._selected)
    },
    partsCountLabel() {
      return this.parts.length
        ? pluralize('part', this.parts.length, true)
        : '--'
    },
    allPartsSelected() {
      return this.selectedParts.length === this.parts.length
    },
  },
  methods: {
    onToggleAllParts() {
      const value = !this.allPartsSelected
      this.parts.forEach(part => {
        this.$emit('updatePart', {
          id: part.id,
          name: '_selected',
          value: value,
        })
      })
    },
    openBulkEditPartsModal() {
      this.$vfm.show('bq-bulk-edit-parts', { technologies: this.technologies })
    },
    deleteSelectedParts() {
      this.selectedParts.forEach(part => {
        this.$emit('deletePart', part.id)
      })
    },
  },
}
</script>
