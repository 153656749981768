import { KJUR } from 'jsrsasign'
import {
  setAccessTokenLastUpdate,
  setRefreshTokenLastUpdate,
  setAccessToken,
  setRefreshToken,
  clearTokens,
} from '@/auth/helpers/storage'

export default {
  SET_USER(state, user) {
    state.user = { ...user }
  },
  SET_ACCESS_TOKEN_LAST_UPDATE(state, newDate) {
    state.accessTokenLastUpdate = newDate

    setAccessTokenLastUpdate(newDate)
  },
  SET_REFRESH_TOKEN_LAST_UPDATE(state, newDate) {
    state.refreshTokenLastUpdate = newDate

    setRefreshTokenLastUpdate(newDate)
  },
  AUTH_SUCCESS(state, { access, refresh }) {
    if (access) {
      const {
        payloadObj: { exp_lifetime: accessTokenLifeTime },
      } = KJUR.jws.JWS.parse(access)

      state.accessToken = access
      state.accessTokenLifeTime = accessTokenLifeTime

      setAccessToken(access)
    }

    if (refresh) {
      const {
        payloadObj: { exp_lifetime: refreshTokenLifeTime },
      } = KJUR.jws.JWS.parse(refresh)

      state.refreshToken = refresh
      state.refreshTokenLifeTime = refreshTokenLifeTime

      setRefreshToken(refresh)
    }
  },
  AUTH_LOGOUT(state) {
    state.accessToken = ''
    state.accessTokenLifeTime = 0
    state.accessTokenLastUpdate = null
    state.refreshToken = ''
    state.refreshTokenLifeTime = 0
    state.refreshTokenLastUpdate = null
    state.user = null

    clearTokens()
  },
  SET_FIRST_NAME(state, firstName) {
    state.user.firstName = firstName
  },
  SET_LAST_NAME(state, lastName) {
    state.user.lastName = lastName
  },
  SET_EMAIL(state, email) {
    state.user.email = email
  },
  SET_PHONE(state, phone) {
    state.user.phone = phone
  },
  SET_COMPANY_NAME(state, companyName) {
    state.user.companyName = companyName
  },
  SET_ZIPCODE(state, zipcode) {
    state.user.zipcode = zipcode
  },
  SET_SHIPPING_FIRST_NAME(state, firstName) {
    state.firstName = firstName
  },
  SET_SHIPPING_LAST_NAME(state, lastName) {
    state.lastName = lastName
  },
  SET_SHIPPING_ADDRESS(state, address) {
    state.address = address
  },
  SET_SHIPPING_PHONE(state, phone) {
    state.phone = phone
  },
  SET_SHIPPING_STATE(state, shippingState) {
    state.shippingState = shippingState
  },
  SET_SHIPPING_CITY(state, city) {
    state.city = city
  },
}
