<template>
  <div>
    <div class="grid u-margin-bottom-small">
      <div class="grid__item">
        <InputText
          label="First Name"
          placeholder="First Name"
          :small="true"
          :disabled="loading"
          v-model="form.firstName"
        />
      </div>
      <div class="grid__item">
        <InputText
          label="Last Name"
          placeholder="Last Name"
          :small="true"
          :disabled="loading"
          v-model="form.lastName"
        />
      </div>
    </div>
    <div class="grid u-margin-bottom-small">
      <div class="grid__item">
        <InputText
          label="Shipping Address"
          placeholder="Shipping Address"
          :small="true"
          :disabled="loading"
          v-model="form.address"
        />
      </div>
      <div class="grid__item">
        <InputText
          :mask="phoneMask"
          label="Phone Number"
          placeholder="Phone number"
          :small="true"
          :disabled="loading"
          :errors="validations.errors.phone"
          :show-errors="submitted"
          v-model="form.phone"
        />
      </div>
    </div>
    <div class="grid u-margin-bottom-medium">
      <div class="grid__item">
        <InputSelect
          label="State"
          placeholder="State"
          :options="statesOptions"
          :small="true"
          :disabled="loading"
          v-model="form.state"
        />
      </div>
      <div class="grid__item">
        <InputText
          label="City"
          placeholder="City"
          :disabled="loading"
          :small="true"
          v-model="form.city"
        />
      </div>
    </div>
    <div class="u-flex-center u-margin-bottom-medium" v-if="errorMessage">
      <span class="account-settings__error">
        {{ errorMessage }}
      </span>
    </div>
    <div class="u-flex-center u-margin-bottom-medium" v-if="successMessage">
      <span class="account-settings__success">
        {{ successMessage }}
      </span>
    </div>
    <div class="u-flex-center">
      <ButtonBase :disabled="loading" @click="submit">
        Save Changes
      </ButtonBase>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import getStates from '@/core/factories/states'
import { phone } from '@/core/plugins/validator/validators'
import { PHONE_MASK } from '@/core/utils/masks'
import { getErrorMessage } from '@/core/helpers/error'

import InputText from '@/core/components/inputs/InputText'
import ButtonBase from '@/core/components/buttons/ButtonBase'
import InputSelect from '@/core/components/inputs/InputSelect'

const validators = {
  phone: [phone()],
}

export default {
  name: 'ShippingInformationForm',
  components: {
    InputText,
    ButtonBase,
    InputSelect,
  },
  data() {
    return {
      statesOptions: getStates(),
      errorMessage: '',
      submitted: false,
      loading: false,
      successMessage: '',

      form: {
        firstName: '',
        lastName: '',
        address: '',
        phone: '',
        state: '',
        city: '',
      },

      phoneMask: PHONE_MASK,
    }
  },
  mounted() {
    this.form.firstName = this.user.shippingFirstName || ''
    this.form.lastName = this.user.shippingLastName || ''
    this.form.address = this.user.shippingAddress || ''
    this.form.phone = this.user.shippingPhoneNumber || ''
    this.form.state = this.user.shippingState || ''
    this.form.city = this.user.shippingCity || ''
  },
  computed: {
    ...mapGetters('auth', ['user']),
    validations() {
      return this.$validator.validate(
        {
          phone: this.form.phone,
        },
        validators
      )
    },
  },
  methods: {
    async submit() {
      this.submitted = true
      this.successMessage = ''

      if (!this.validations.isValid || this.loading) return

      try {
        this.loading = true

        await this.$store.dispatch('auth/updateAccount', {
          shippingFirstName: this.form.firstName,
          shippingLastName: this.form.lastName,
          shippingAddress: this.form.address,
          shippingPhoneNumber: this.form.phone,
          shippingCity: this.form.city,
          shippingState: this.form.state,
        })
        this.successMessage = 'Shipping information has been saved'
        setTimeout(() => {
          this.successMessage = ''
        }, 5 * 1000)
      } catch (err) {
        this.errorMessage = getErrorMessage(err)
        setTimeout(() => {
          this.errorMessage = ''
        }, 5 * 1000)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
