<template>
  <div class="basic_upload-layout">
    <transition name="fade" appear mode="out-in">
      <AppLoader v-if="loading" />
    </transition>
    <div class="basic_upload-layout__content">
      <div class="basic_upload-layout__header">
        <AppHeader :show-logo="true" />
      </div>
      <main class="basic_upload-layout__main">
        <slot />
      </main>
      <div class="basic_upload-layout__footer">
        <AppFooter />
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from '@/core/components/layout/AppHeader'
import AppFooter from '@/core/components/layout/AppFooter'
import AppLoader from '@/core/components/layout/AppLoader'

export default {
  name: 'BasicUploadLayout',
  components: {
    AppHeader,
    AppFooter,
    AppLoader,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
