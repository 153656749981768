<template>
  <BasicUploadLayout :loading="loading">
    <div class="container u-margin-top-small">
      <div class="u-margin-bottom-xsmall">
        <h1>Customer Contact Information</h1>
      </div>
      <div class="u-margin-bottom-small">
        <div class="auth-form__group">
          <div class="auth-form__item">
            <InputText
              v-model="firstName"
              inputName="first-name"
              placeholder="First Name"
              :errors="validations.errors.firstName"
              :show-errors="submitted"
            />
          </div>
          <div class="auth-form__item">
            <InputText
              v-model="lastName"
              inputName="last-name"
              placeholder="Last Name"
              :errors="validations.errors.lastName"
              :show-errors="submitted"
            />
          </div>
        </div>
        <div class="auth-form__group">
          <div class="auth-form__item">
            <InputText
              v-model="companyName"
              inputName="company-name"
              placeholder="Company Name"
              :errors="validations.errors.companyName"
              :show-errors="submitted"
            />
          </div>
        </div>
        <div class="auth-form__group">
          <div class="auth-form__item">
            <InputText
              v-model="email"
              inputName="email"
              placeholder="Email"
              :errors="validations.errors.email"
              :show-errors="submitted"
            />
          </div>
          <div class="auth-form__item">
            <InputText
              v-model="phone"
              :mask="phoneMask"
              inputName="phone-number"
              placeholder="Phone (optional)"
              :errors="validations.errors.phone"
              :show-errors="submitted"
            />
          </div>
        </div>
      </div>
      <div class="auth-form__group auth-form__group-top">
        <div class="auth-form__item">
          <div class="u-margin-bottom-xsmall">
            <label class="input-select__label">Requested Delivery Date</label>
          </div>
          <div class="u-margin-bottom-small">
            <Datepicker
              v-model="requestedDeliveryDate"
              class="input-text__elem"
            />
          </div>
        </div>
        <div class="auth-form__item">
          <InputSelect
            label="Priority"
            :options="priorityOptions"
            v-model="priority"
          />
        </div>
        <div class="auth-form__item">
          <div class="u-margin-bottom-small">
            <InputText
              v-model="internalNotes"
              :textarea="true"
              label="Internal Notes (Private)"
              inputName="internal-notes"
            />
          </div>
        </div>
      </div>
      <div class="u-margin-bottom-small">
        <InputCheckbox v-model="sendToChina">
          Send to China
        </InputCheckbox>
      </div>
      <div class="u-margin-bottom-xsmall">
        <h1>File Upload / File Part Specifications</h1>
      </div>
      <ItarSelect
        :itar-option="itarOption"
        @change="onChangeItarOption"
        class="u-margin-top-small"
      />
      <div class="u-margin-bottom-small">
        <FileUploader
          :disabled="itarOption !== 'no'"
          :files="files"
          :accepted-formats="acceptedFormats"
          :session-id="sessionId"
          @uploadRequest="onFilesUploadRequest"
          @fileRemove="onFileRemove"
          @fileStatusChanged="onFileStatusChanged"
          @reject="onReject"
          @fileUnitsChanged="onFileUnitsChanged"
        />
      </div>
      <div v-if="uploadedFiles.length" class="u-margin-bottom-small">
        <PartsTable
          :parts="uploadedFiles"
          :technologies="technologies"
          @updatePart="updatePart"
          @deletePart="deletePart"
        />
      </div>
      <div v-if="errorMessage" class="u-flex-center">
        <p class="paragraph paragraph--red">
          {{ errorMessage }}
        </p>
      </div>
      <div class="u-flex-center u-margin-bottom-small">
        <ButtonPrimary @click="submit">Submit</ButtonPrimary>
      </div>
    </div>
    <SuccessBasicQuoteModal />
    <BQBulkEditPartsModal @bulkEditParts="onBulkEditParts" />
    <PartDocumentsModal />
  </BasicUploadLayout>
</template>

<script>
import Datepicker from 'vue3-datepicker'
import { v4 } from 'uuid'

import {
  email,
  phone,
  required,
  isValidName,
} from '@/core/plugins/validator/validators'
import { fetchTechnologies } from '@/api/commonApi'
import { PHONE_MASK } from '@/core/utils/masks'
import { getErrorMessage } from '@/core/helpers/error'
import { acceptedFormats } from '@/core/helpers/accepted-formats'
import { FileStatuses, TechnologyTypes } from '@/core/utils/constants'
import { createPaperlessBasicQuote } from '@/api/quotesApi'
import BasicUploadLayout from '../layouts/BasicUploadLayout'
import InputText from '@/core/components/inputs/InputText'
import FileUploader from '@/core/components/controls/file-uploader/FileUploader'
import ButtonPrimary from '@/core/components/buttons/ButtonPrimary'
import moment from 'moment'
import SuccessBasicQuoteModal from '../components/modals/SuccessBasicQuoteModal'
import PartsTable from '../components/PartsTable'
import InputCheckbox from '@/core/components/inputs/InputCheckbox'
import BQBulkEditPartsModal from '@/basic_upload/components/modals/BQBulkEditPartsModal'
import PartDocumentsModal from '@/quote/components/summary/modals/PartDocumentsModal'
import InputSelect from '@/core/components/inputs/InputSelect'
import ItarSelect from '@/core/components/controls/ItarSelect'

const validators = {
  firstName: [required(), isValidName()],
  lastName: [required(), isValidName()],
  email: [required(), email()],
  phone: [phone()],
  companyName: [required()],
}

export default {
  name: 'BasicUpload',
  components: {
    ItarSelect,
    InputSelect,
    BQBulkEditPartsModal,
    InputCheckbox,
    PartDocumentsModal,
    PartsTable,
    SuccessBasicQuoteModal,
    ButtonPrimary,
    BasicUploadLayout,
    InputText,
    Datepicker,
    FileUploader,
  },
  data() {
    return {
      loading: true,
      firstName: '',
      lastName: '',
      companyName: '',
      email: '',
      phone: '',
      requestedDeliveryDate: Date(),
      files: [],
      quoteNotes: '',
      phoneMask: PHONE_MASK,
      authenticated: false,
      technologies: [],
      errorMessage: '',
      sendToChina: false,
      sessionId: '',
      internalNotes: '',
      submitted: false,
      priority: 10,
      itarOption: null,
    }
  },
  async created() {
    this.sessionId = v4()
    this.loading = false
  },
  async mounted() {
    const { data } = await fetchTechnologies()
    Object.keys(data).forEach(key => {
      const newTechnology = {
        ...data[key],
        id: key,
      }
      if (newTechnology.id != TechnologyTypes.MultipleProcesses) {
        this.technologies.push(newTechnology)
      }
    })
    this.technologies.sort((a, b) => {
      if (a.title > b.title) {
        return 1
      }
      if (a.title < b.title) {
        return -1
      }
      return 0
    })
  },
  computed: {
    validations() {
      return this.$validator.validate(
        {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          phone: this.phone,
          companyName: this.companyName,
        },
        validators
      )
    },
    uploadedFiles() {
      return this.files.filter(file => file.status === FileStatuses.UPLOADED)
    },
    allFilesUploaded() {
      return (
        this.files.length > 0 && this.uploadedFiles.length === this.files.length
      )
    },
    acceptedFormats() {
      return acceptedFormats({
        is3D: true,
        isSheetMetal: true,
      })
    },
    allFilesWithAcceptedFormats() {
      if (!this.allFilesUploaded) return true
      return (
        this.files.filter(file =>
          this.acceptedFormats.find(
            format =>
              file.original.name.toLowerCase().indexOf(`.${format}`) !== -1
          )
        ).length === this.files.length
      )
    },
    allFilesProcessed() {
      const processedFiles = this.files.filter(
        part => part.streamicsStatus === 'processed'
      ).length
      return this.files.length > 0 && processedFiles === this.files.length
    },
    priorityOptions() {
      return [...Array(10).keys()].reverse().map(i => {
        let result = {}
        if (i === 9) {
          result = { title: `${i + 1} (Lowest)`, value: i + 1 }
        } else if (i === 0) {
          result = { title: `${i + 1} (Highest)`, value: i + 1 }
        } else {
          result = { title: i + 1, value: i + 1 }
        }
        return result
      })
    },
  },
  methods: {
    onFilesUploadRequest(files) {
      files.forEach(file =>
        this.files.push({
          ...file,
          technologyId: null,
          materialId: null,
          finishId: null,
          quantity: 1,
          notes: null,
          qualityRequirements: null,
          _selected: false,
          streamicsStatus: 'waiting',
          documents: [],
          manufacturingNotes: null,
        })
      )
    },
    onFileRemove(id) {
      const index = this.files.findIndex(file => file.id === id)

      if (index !== -1) {
        this.files.splice(index, 1)
      }
    },
    onFileStatusChanged({ id, status, partId }) {
      const index = this.files.findIndex(file => file.id === id)

      if (index !== -1) {
        this.files[index].status = status

        if (partId) {
          this.files[index].partId = partId
        }
      }
    },
    onReject(value) {
      this.isRejectUploading = value
    },
    onFileUnitsChanged({ id, units }) {
      const index = this.files.findIndex(file => file.id === id)
      if (index !== -1) {
        this.files[index].units = units
      }
    },
    async submit() {
      this.submitted = true
      if (!this.validations.isValid || this.loading) return
      if (!this.allFilesProcessed) {
        this.errorMessage = 'Not all files processed!'
        return
      }
      if (this.itarOption !== 'no') {
        this.errorMessage =
          'Cannot process ITAR files - Please confirm files are non-ITAR'
        return
      }
      this.loading = true
      try {
        const access_token = this.authState.accessToken.value
        const payload = {
          email: this.email,
          firstName: this.firstName,
          lastName: this.lastName,
          company: this.companyName,
          phone: this.phone,
          privateNotes: this.internalNotes,
          paperlessRequestedDeliveryDate: moment(
            this.requestedDeliveryDate
          ).format('YYYY-MM-DD'),
          paperlessSendToChina: this.sendToChina,
          oktaUser: this.authState.idToken.claims.name,
          oktaEmail: this.authState.idToken.claims.email,
          parts: this.files.map(file => ({
            partId: file.partId,
            units: file.units,
            technologyId: file.technologyId,
            materialId: file.materialId,
            finishId: file.finishId,
            quantity: file.quantity,
            notes: file.notes,
            privateNotes: file.manufacturingNotes,
            documents: file.documents.map(item => ({ id: item.id })),
            qualityRequirements: file.qualityRequirements,
          })),
          paperlessPriority: this.priority,
          access_token,
        }
        const { data } = await createPaperlessBasicQuote(payload)
        this.$vfm.show('success-basic-quote', {
          quoteId: data.paperlessQuoteNumber,
        })
      } catch (err) {
        this.errorMessage = getErrorMessage(err)
      } finally {
        this.loading = false
      }
    },
    updatePart(payload) {
      const findIndex = this.files.findIndex(item => item.id === payload.id)
      if (findIndex !== -1) {
        if (payload.name === 'technologyId') {
          const findingTechnology = this.technologies.find(
            technology => technology.id == payload.value
          )
          if (findingTechnology) {
            if (
              findingTechnology.defaultFinishId &&
              findingTechnology.defaultFinishId in findingTechnology.finishes
            ) {
              this.files[findIndex]['finishId'] =
                findingTechnology.defaultFinishId
            } else {
              this.files[findIndex]['finishId'] = null
            }
          }
        }

        this.files[findIndex][payload.name] = payload.value
      }
    },
    onBulkEditParts(parentPart) {
      this.files
        .filter(part => part._selected)
        .forEach(part => {
          Object.entries(parentPart).forEach(([key, value]) => {
            if (value) {
              part[key] = value
            }
          })
        })
    },
    deletePart(partId) {
      const findIndex = this.files.findIndex(item => item.id === partId)
      if (findIndex !== -1) {
        this.files.splice(findIndex, 1)
      }
    },
    onChangeItarOption(value) {
      this.itarOption = value
    },
  },
}
</script>
