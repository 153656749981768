<template>
  <div class="part">
    <div class="part__row part__row-center">
      <div v-if="partCalculation" class="part__cell part__cell--checkbox">
        <AppSpinner in-place="true" />
      </div>
      <div
        v-else-if="partProcessed"
        class="part__cell part__cell--checkbox part__cell--icon-complete"
      >
        <i class="fas fa fa-check" aria-hidden="true"></i>
      </div>
      <div
        v-else
        class="part__cell part__cell--checkbox part__cell--icon-error"
      >
        <a
          class="link--strong link--red u-pointer"
          href="#"
          @click.prevent="$emit('deletePart', part.partId)"
          ><i class="fas fa fa-times" aria-hidden="true"></i>
        </a>
      </div>
      <div class="part__cell part__cell--checkbox">
        <InputCheckbox
          :model-value="part._selected"
          :disabled="locked"
          @update:modelValue="togglePart"
        />
      </div>
      <div class="part__cell part__cell--name">
        <p class="part__name">
          {{ partName }}
        </p>
      </div>
      <template v-if="partError">
        <div class="part__cell part__cell--full">
          <p class="u-text-center paragraph paragraph--grey">
            Part failed to be processed. Please create the quote and upload the
            part in Paperless Parts to Proceed. Or, you can click
            <a
              class="link--strong link--black u-underline u-pointer"
              href="#"
              @click.prevent="$emit('deletePart', part.partId)"
              >here</a
            >
            to delete the part and proceed with the quote.
          </p>
        </div>
      </template>
      <template v-else>
        <div class="part__cell part__cell--quantity">
          <InputQuantity
            height="5rem"
            :model-value="part.quantity"
            :max-value="maxQuantity"
            @update:modelValue="onQuantityChanged"
          />
        </div>
        <div class="part__cell part__cell--technology">
          <InputSelect
            height="5rem"
            placeholder="Select technology"
            :model-value="part.technologyId"
            :options="technologiesOptions"
            @update:modelValue="onTechnologyChanged"
          />
        </div>
        <div class="part__cell part__cell--material">
          <InputSelect
            height="5rem"
            placeholder="Select material"
            :disabled="disableSelectMaterialAndFinish"
            :model-value="part.materialId"
            :options="materialsOptions"
            @update:modelValue="onMaterialChanged"
          />
        </div>
        <div class="part__cell part__cell--finish">
          <InputSelect
            height="5rem"
            :disabled="disableSelectMaterialAndFinish"
            :placeholder="finishLabel"
            :options="finishesOptions"
            :model-value="part.finishId"
            @update:modelValue="onFinishChanged"
          />
        </div>
        <div class="part__cell part__cell--quality part__cell--small">
          <InputCheckbox
            v-for="item in qualityRequirements"
            :key="item.title"
            v-model="item.value"
            @update:modelValue="onQuialityItemChange"
          >
            {{ item.title }}
          </InputCheckbox>
        </div>
      </template>
    </div>
    <div class="part__row part__row-center"></div>
    <template v-if="!partError">
      <div class="part__row part__row--top">
        <div class="part__note part__note-pad-left">
          <div class="u-margin-bottom-xsmall">
            <InputText
              :model-value="part.notes"
              :textarea="true"
              label="Notes (Visible to Customer when finalized)"
              width="50rem"
              @update:modelValue="onNotesChanged"
            ></InputText>
          </div>
        </div>
        <div class="part__cell part__cell--small" @click="attachFiles">
          <PartFiles :files="part.documents" />
        </div>
      </div>
      <div class="part__row part__row--top">
        <div class="part__note part__note-pad-left">
          <div class="u-margin-bottom-small">
            <InputText
              :model-value="part.manufacturingNotes"
              :textarea="true"
              label="Manufacturing Notes (Private)"
              inputName="manufacturing-notes"
              width="50rem"
              @update:modelValue="onManufacturingNotesChanged"
            />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { TechnologyTypes } from '@/core/utils/constants'
import InputSelect from '@/core/components/inputs/InputSelect'
import InputQuantity from '@/core/components/inputs/InputQuantity'
import PartFiles from '@/quote/components/summary/PartFiles'
import InputText from '@/core/components/inputs/InputText'
import InputCheckbox from '@/core/components/inputs/InputCheckbox'
import AppSpinner from '@/core/components/content/AppSpinner'
import getSocket from '@/core/utils/socket'

export default {
  name: 'PartRow',
  components: {
    AppSpinner,
    InputText,
    InputSelect,
    InputQuantity,
    InputCheckbox,
    PartFiles,
  },
  props: {
    part: {
      type: Object,
      required: true,
    },
    technologies: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      qualityRequirements: [
        {
          title: '3D Scan Overlay',
          value: false,
        },
        {
          title: 'CoC',
          value: false,
        },
        {
          title: 'FAI AS 9100',
          value: false,
        },
        {
          name: 'Injection Molding | IQ/OQ/PQ',
          title: 'IM | IQ/OQ/PQ',
          value: false,
        },
        {
          name: 'Injection Molding | Level III PPAP',
          title: 'IM | Level III PPAP',
          value: false,
        },
        {
          title: 'Material Cert',
          value: false,
        },
      ],
      socket: null,
    }
  },
  computed: {
    disableSelectMaterialAndFinish() {
      let result = false
      if (
        this.selectedTechnology &&
        [TechnologyTypes.InjectionMolding, TechnologyTypes.SheetMetal].includes(
          parseInt(this.selectedTechnology.id)
        )
      ) {
        result = true
      }
      return result
    },
    partName() {
      let result = ''
      if (this.part && this.part.original && this.part.original.name)
        result =
          this.part.original.name.length > 20
            ? `${this.part.original.name.substring(0, 20)}...`
            : this.part.original.name
      return result
    },
    maxQuantity() {
      return 1000000
    },
    selectedTechnology() {
      return this.technologies.find(
        technology => technology.id == this.part.technologyId
      )
    },
    technologiesOptions() {
      return this.technologies.map(technology => ({
        title: technology.title,
        value: technology.id,
      }))
    },
    materialsOptions() {
      if (this.part.technologyId) {
        const result = []
        Object.keys(this.selectedTechnology.materials).forEach(key => {
          const material = {
            title: this.selectedTechnology.materials[key.toString()].title,
            value: key,
          }
          result.push(material)
        })
        result.sort(this.sortOptions)
        return result
      }

      return []
    },
    finishesOptions() {
      if (this.selectedTechnology) {
        const result = []
        Object.keys(this.selectedTechnology.finishes).forEach(key => {
          const finish = {
            title: this.selectedTechnology.finishes[key.toString()].title,
            value: key,
          }
          result.push(finish)
          result.sort(this.sortOptions)
        })
        result.unshift({
          title: 'No Finish',
          value: null,
        })
        return result
      }

      return []
    },
    finishLabel() {
      return this.finishesOptions.length ? 'Select finish' : 'No options'
    },
    qualityRequirementsStr() {
      return this.qualityRequirements
        .filter(item => item.value)
        .map(item => {
          if (item.name) {
            return item.name
          } else {
            return item.title
          }
        })
        .join(',')
    },
    partProcessed() {
      return this.part.streamicsStatus === 'processed'
    },
    partCalculation() {
      return this.part.streamicsStatus === 'waiting'
    },
    partError() {
      return this.part.streamicsStatus === 'error'
    },
  },
  async created() {
    this.socket = getSocket()

    await this.socket.connect()

    this.socket.on('part_streamics_complete', async data => {
      console.log(
        `event part_streamics_complete data: ${JSON.stringify(data['partId'])}`
      )
      this.$emit('updatePart', {
        id: this.part.id,
        name: 'streamicsStatus',
        value: data['status'],
      })
    })
  },
  async mounted() {
    await this.socket.emit('join', {
      room: `part-${this.part.partId}`,
    })
  },
  methods: {
    onQuantityChanged(quantity) {
      this.$emit('updatePart', {
        id: this.part.id,
        name: 'quantity',
        value: quantity,
      })
    },
    onTechnologyChanged(technologyId) {
      this.selectedTechnology = this.technologies.find(
        item => item.id === technologyId
      )
      this.$emit('updatePart', {
        id: this.part.id,
        name: 'technologyId',
        value: technologyId,
      })
    },
    onMaterialChanged(materialId) {
      this.$emit('updatePart', {
        id: this.part.id,
        name: 'materialId',
        value: materialId,
      })
    },
    onFinishChanged(finishId) {
      this.$emit('updatePart', {
        id: this.part.id,
        name: 'finishId',
        value: finishId,
      })
    },
    onNotesChanged(notes) {
      this.$emit('updatePart', {
        id: this.part.id,
        name: 'notes',
        value: notes,
      })
    },
    onManufacturingNotesChanged(notes) {
      this.$emit('updatePart', {
        id: this.part.id,
        name: 'manufacturingNotes',
        value: notes,
      })
    },
    sortOptions(a, b) {
      if (a.title > b.title) {
        return 1
      }
      if (a.title < b.title) {
        return -1
      }
      return 0
    },
    attachFiles() {
      this.$vfm.show('part-documents', {
        id: this.part.partId,
        documents: this.part.documents || [],
        onSubmit: documents => {
          this.$emit('updatePart', {
            id: this.part.id,
            name: 'documents',
            value: documents,
          })
        },
      })
    },
    onQuialityItemChange() {
      this.$emit('updatePart', {
        id: this.part.id,
        name: 'qualityRequirements',
        value: this.qualityRequirementsStr,
      })
    },
    togglePart() {
      this.$emit('updatePart', {
        id: this.part.id,
        name: '_selected',
        value: !this.part._selected,
      })
    },
  },
}
</script>

<style scoped></style>
