import BasicUpload from './pages/BasicUpload'

export default [
  {
    path: '/basic_upload',
    name: 'basic_upload',
    component: BasicUpload,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
]
