<template>
  <form @submit.prevent="register" class="auth-form">
    <transition name="fade" appear mode="out-in">
      <div v-if="loading" class="auth-form__loading">
        <AppSpinner />
      </div>
    </transition>
    <div class="auth-form__group">
      <div class="auth-form__item">
        <InputText
          v-model="firstName"
          inputName="first-name"
          placeholder="First Name"
          :errors="validations.errors.firstName"
          :show-errors="submitted"
        />
      </div>
      <div class="auth-form__item">
        <InputText
          v-model="lastName"
          inputName="last-name"
          placeholder="Last Name"
          :errors="validations.errors.lastName"
          :show-errors="submitted"
        />
      </div>
    </div>
    <div class="auth-form__group">
      <div class="auth-form__item">
        <InputText
          v-model="companyName"
          inputName="company-name"
          placeholder="Company Name"
          :errors="validations.errors.companyName"
          :show-errors="submitted"
        />
      </div>
    </div>
    <div class="auth-form__group">
      <div class="auth-form__item">
        <InputText
          v-model="workEmail"
          inputName="email"
          placeholder="Work Email"
          :errors="validations.errors.workEmail"
          :show-errors="submitted"
        />
      </div>
    </div>
    <div class="auth-form__group">
      <div class="auth-form__item">
        <InputText
          v-model="phone"
          :mask="phoneMask"
          inputName="phone-number"
          placeholder="Phone"
          :errors="validations.errors.phone"
          :show-errors="submitted"
        />
      </div>
    </div>
    <div class="auth-form__group">
      <div class="auth-form__item">
        <InputPassword v-model="password" />
      </div>
      <div class="auth-form__item">
        <InputText
          v-model="passwordConfirmation"
          type="password"
          placeholder="Confirm Password"
          :errors="validations.errors.passwordConfirmation"
          :show-errors="submitted"
        />
      </div>
    </div>
    <div class="auth-form__group">
      <div class="auth-form__item">
        <InputCheckbox
          label-color="white"
          name="terms_agree"
          v-model="termsAgree"
        >
          I agree to Fathom's
          <a
            class="link link--black"
            href="https://fathommfg.com/terms-and-conditions"
            target="_blank"
          >
            Terms and Conditions
          </a>
          and
          <a
            class="link link--black"
            href="https://fathommfg.com/privacy-policy"
            target="_blank"
          >
            Privacy Policy
          </a>
        </InputCheckbox>
      </div>
    </div>
    <div class="auth-form__actions">
      <ButtonSubmit id="registration" :disabled="!termsAgree">
        Create An Account
      </ButtonSubmit>
    </div>
    <transition name="fade" appear mode="out-in">
      <div v-if="errorMessage" class="auth-form__error">
        <p class="paragraph paragraph--red">{{ errorMessage }}</p>
      </div>
    </transition>
  </form>
</template>

<script>
import { createAccount } from '@/api/accountApi'
import {
  email,
  phone,
  required,
  minLength,
  sameAs,
  isValidName,
  minSymbolCount,
  noDuplicates,
} from '@/core/plugins/validator/validators'
import { Pardot } from '@/core/services/pardot'

import { PHONE_MASK } from '@/core/utils/masks'
import { getErrorMessage } from '@/core/helpers/error'

import InputText from '@/core/components/inputs/InputText'
import InputCheckbox from '@/core/components/inputs/InputCheckbox'
import ButtonSubmit from '@/core/components/buttons/ButtonSubmit'
import AppSpinner from '@/core/components/content/AppSpinner'
import InputPassword from '@/core/components/inputs/InputPassword'

const validators = {
  firstName: [required(), isValidName()],
  lastName: [required(), isValidName()],
  workEmail: [required(), email()],
  phone: [required(), phone()],
  password: [required(), minSymbolCount(1), noDuplicates(), minLength(12)],
  passwordConfirmation: [required(), sameAs('password')],
  companyName: [required()],
}

export default {
  name: 'RegistrationForm',
  components: {
    InputPassword,
    InputText,
    ButtonSubmit,
    AppSpinner,
    InputCheckbox,
  },
  data() {
    return {
      phoneMask: PHONE_MASK,
      loading: false,
      submitted: false,
      errorMessage: '',

      firstName: '',
      lastName: '',
      companyName: '',
      workEmail: '',
      phone: '',
      password: '',
      passwordConfirmation: '',
      termsAgree: false,
    }
  },
  computed: {
    validations() {
      return this.$validator.validate(
        {
          firstName: this.firstName,
          lastName: this.lastName,
          workEmail: this.workEmail,
          phone: this.phone,
          password: this.password,
          passwordConfirmation: this.passwordConfirmation,
          companyName: this.companyName,
        },
        validators
      )
    },
  },
  methods: {
    async register() {
      this.submitted = true

      if (!this.validations.isValid || this.loading) return

      this.loading = true

      let recaptchaToken = ''
      try {
        await this.$recaptchaLoaded()
        recaptchaToken = await this.$recaptcha('login')
      } catch (err) {
        if (err) {
          this.errorMessage = getErrorMessage(err)
        }

        this.loading = false
      }

      if (!recaptchaToken) {
        if (process.env.NODE_ENV === 'development') recaptchaToken = ''
        else return
      }

      try {
        const { data } = await createAccount({
          phoneNumber: this.phone,
          company: this.companyName,
          email: this.workEmail,
          firstName: this.firstName,
          lastName: this.lastName,
          password: this.password,
          recaptchaToken,
        })

        Pardot.send({
          emailAddress: this.workEmail,
          userId: data.id,
          firstName: this.firstName,
          lastName: this.lastName,
          company: this.companyName,
          phone: this.phone,
          zipcode: '',
        })

        await this.$store.dispatch('auth/login', {
          email: this.workEmail,
          password: this.password,
        })

        this.$vfm.hide('auth')
        this.$emit('success')
        this.$vfm.show('customer-info')
      } catch (err) {
        this.errorMessage = getErrorMessage(err)
      } finally {
        setTimeout(() => {
          this.loading = false
        }, 300)
      }
    },
  },
}
</script>
