<template>
  <AppModal
    name="forgot-password"
    title="Forgot password"
    :small="true"
    @beforeOpen="onBeforeOpen"
    @close="$vfm.hide('forgot-password')"
    @closed="onClosed"
  >
    <div class="reset-password-form">
      <h3 class="auth-modal__title">
        Forgot password
      </h3>
      <div class="u-text-center u-margin-bottom-medium">
        <p class="paragraph paragraph--grey u-text-center" v-if="!requestSent">
          Enter your email address that is<br />
          registered with Fathom
        </p>
        <p class="paragraph paragraph--grey" v-else>
          An email has been sent to your registered<br />
          email address with instructions to reset your password
        </p>
      </div>
      <div class="u-margin-bottom-medium" v-if="!requestSent">
        <InputText
          type="email"
          label="Email Address"
          placeholder="Registered Email Address"
          :disabled="loading"
          :errors="validations.errors.email"
          :show-errors="submitted"
          v-model="email"
        />
      </div>
      <div>
        <ButtonSubmit
          v-if="!requestSent"
          :disabled="loading"
          :block="true"
          @click="sendRequest"
        >
          Reset password
        </ButtonSubmit>
        <ButtonSubmit v-else :block="true" @click="hide">
          Close
        </ButtonSubmit>
      </div>
    </div>
  </AppModal>
</template>

<script>
import { email, required } from '@/core/plugins/validator/validators'
import { sendForgotPasswordRequest } from '@/api/accountApi'
import { getErrorMessage } from '@/core/helpers/error'

import AppModal from '@/core/components/modals/AppModal'
import ButtonSubmit from '@/core/components/buttons/ButtonSubmit'
import InputText from '@/core/components/inputs/InputText'

const validators = {
  email: [required(), email()],
}

export default {
  name: 'ForgotPasswordModal',
  components: {
    AppModal,
    ButtonSubmit,
    InputText,
  },
  data() {
    return {
      email: '',
      loading: false,
      requestSent: false,
      errorMessage: '',
      submitted: false,
    }
  },
  computed: {
    validations() {
      return this.$validator.validate(
        {
          email: this.email,
        },
        validators
      )
    },
  },
  methods: {
    onBeforeOpen() {},
    async sendRequest() {
      this.submitted = true

      if (!this.validations.isValid || this.loading) return

      try {
        this.loading = true

        await sendForgotPasswordRequest({ email: this.email })

        this.requestSent = true
      } catch (err) {
        this.errorMessage = getErrorMessage(err)
      } finally {
        this.loading = false
      }
    },
    hide() {
      this.$vfm.hide('forgot-password')
    },
    onClosed() {
      this.email = ''
      this.loading = false
      this.requestSent = false
      this.errorMessage = ''
      this.submitted = false
    },
  },
}
</script>
