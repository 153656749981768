<template>
  <ErrorOverlay :error="error" />
</template>

<script>
import ErrorOverlay from '@/core/components/errors/ErrorOverlay'

export default {
  name: 'NotFound',
  components: {
    ErrorOverlay,
  },
  data() {
    return {
      error: {
        status: 404,
      },
    }
  },
}
</script>
