<template>
  <AppModal
    name="check-activity"
    :small="true"
    :esc-to-close="true"
    @close="$vfm.hide('check-activity')"
  >
    <div class="u-flex-center u-margin-bottom-small">
      <h2>Are you still quoting?</h2>
    </div>
    <div class="u-flex-center u-margin-bottom-small">
      <ButtonPrimary @click="refreshTokens">Yes</ButtonPrimary>
      <ButtonPrimary @click="logout">No</ButtonPrimary>
    </div>
  </AppModal>
</template>

<script>
import AppModal from '@/core/components/modals/AppModal'
import ButtonPrimary from '@/core/components/buttons/ButtonPrimary'
export default {
  name: 'CheckActivityModal',
  components: { ButtonPrimary, AppModal },
  methods: {
    async refreshTokens() {
      await this.$store.dispatch('auth/refresh')
      this.$vfm.hide('check-activity')
    },
    async logout() {
      await this.$store.dispatch('auth/logout')
      this.$vfm.hide('check-activity')
    },
  },
}
</script>
