<template>
  <div>
    <div class="grid u-margin-bottom-small">
      <div class="grid__item">
        <InputText
          label="Current Password"
          placeholder="Current Password"
          type="password"
          :small="true"
          :errors="validations.errors.currentPassword"
          :show-errors="submitted"
          :disabled="loading"
          v-model="currentPassword"
        />
      </div>
    </div>
    <div class="grid u-margin-bottom-small">
      <div class="grid__item">
        <InputPassword
          label="New Password"
          placeholder="New Password"
          :small="true"
          :disabled="loading"
          v-model="newPassword"
        />
      </div>
    </div>
    <div class="grid u-margin-bottom-medium">
      <div class="grid__item">
        <InputText
          label="Re-Enter New Password"
          placeholder="Re-Enter New Password"
          type="password"
          :small="true"
          :errors="validations.errors.confirmPassword"
          :show-errors="submitted"
          :disabled="loading"
          v-model="confirmPassword"
        />
      </div>
    </div>
    <div class="u-flex-center u-margin-bottom-medium" v-if="errorMessage">
      <span class="account-settings__error">
        {{ errorMessage }}
      </span>
    </div>
    <div class="u-flex-center u-margin-bottom-medium" v-if="successMessage">
      <span class="account-settings__success">
        {{ successMessage }}
      </span>
    </div>
    <div class="u-flex-center">
      <ButtonBase :disabled="loading" @click="submit">
        Save Changes
      </ButtonBase>
    </div>
  </div>
</template>

<script>
import {
  required,
  sameAs,
  minLength,
  minSymbolCount,
  noDuplicates,
} from '@/core/plugins/validator/validators'
import { updateAccountPassword } from '@/api/accountApi'

import InputText from '@/core/components/inputs/InputText'
import ButtonBase from '@/core/components/buttons/ButtonBase'
import { getErrorMessage } from '@/core/helpers/error'
import InputPassword from '@/core/components/inputs/InputPassword'

const validators = {
  currentPassword: [required()],
  newPassword: [required(), minLength(12), minSymbolCount(1), noDuplicates()],
  confirmPassword: [required(), sameAs('newPassword')],
}

export default {
  name: 'ChangePasswordForm',
  components: {
    InputPassword,
    InputText,
    ButtonBase,
  },
  data() {
    return {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      errorMessage: '',
      submitted: false,
      loading: false,
      successMessage: '',
    }
  },
  computed: {
    validations() {
      return this.$validator.validate(
        {
          currentPassword: this.currentPassword,
          newPassword: this.newPassword,
          confirmPassword: this.confirmPassword,
        },
        validators
      )
    },
  },
  methods: {
    async submit() {
      this.submitted = true
      this.successMessage = ''

      if (!this.validations.isValid || this.loading) return

      try {
        this.loading = true

        await updateAccountPassword({
          currentPassword: this.currentPassword,
          newPassword: this.newPassword,
        })

        this.successMessage = 'Setting password successfully'
        this.$emit('changed', true)
        setTimeout(() => {
          this.successMessage = ''
        }, 5 * 1000)
      } catch (err) {
        this.errorMessage = getErrorMessage(err)
        this.$emit('changed', false)
        setTimeout(() => {
          this.errorMessage = ''
        }, 5 * 1000)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
