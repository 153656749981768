<template>
  <AppModal
    name="success-basic-quote"
    :small="true"
    @closed="onClosed"
    @beforeOpen="onBeforeOpen"
    @close="$vfm.hide('success-basic-quote')"
  >
    <div class="u-flex-center u-margin-bottom-xsmall">
      <h1>Thank you for submitting this quote</h1>
    </div>
    <div class="u-flex-center u-margin-bottom-small">
      <h2>Quote id:{{ quoteId }}</h2>
    </div>
    <div class="u-flex-center">
      <ButtonPrimary @click="$vfm.hide('success-basic-quote')"
        >Close</ButtonPrimary
      >
    </div>
  </AppModal>
</template>

<script>
import AppModal from '@/core/components/modals/AppModal'
import ButtonPrimary from '../../../core/components/buttons/ButtonPrimary'

export default {
  name: 'SuccessBasicQuoteModal',
  components: { ButtonPrimary, AppModal },
  data() {
    return {
      quoteId: '',
    }
  },
  methods: {
    onClosed() {
      this.$router.push({ name: 'main' })
    },
    onBeforeOpen(e) {
      if ('quoteId' in e.ref.params.value) {
        this.quoteId = e.ref.params.value.quoteId
      }
    },
  },
}
</script>
