export default {
  isLoggedIn(state) {
    return !!state.accessToken
  },
  user(state) {
    return state.user
  },
  isReseller(state) {
    return state.user ? state.user.reseller : false
  },
  shipping(state) {
    if (state.user) {
      return {
        firstName: state.user.shippingAddress,
        lastName: state.user.shippingLastName,
        phoneNumber: state.user.shippingPhoneNumber,
        address: state.user.shippingAddress,
        city: state.user.shippingCity,
        state: state.user.shippingState,
      }
    }

    return null
  },
  poAuthorized(state) {
    return state.user ? state.user.poAuthorized : false
  },
  isAccessTokenValid: state => now =>
    now < state.accessTokenLastUpdate + state.accessTokenLifeTime,
  isRefreshTokenValid: state => now =>
    now < state.refreshTokenLastUpdate + state.refreshTokenLifeTime,
}
