<template>
  <AppModal
    name="auth"
    :small="true"
    :closable="closable"
    @beforeOpen="onBeforeOpen"
    @closed="onClosed"
    @close="$vfm.hide('auth')"
  >
    <div class="auth-modal">
      <h5 v-if="uploadingFiles" class="auth-modal__subtitle">
        We are uploading your files
      </h5>
      <h3 class="auth-modal__title">
        Please sign in or create an account
      </h3>
      <div class="auth-modal__body">
        <div class="auth-modal__tabs">
          <div
            class="auth-modal__tab"
            :class="{ 'auth-modal__tab--selected': mode === 'sign-in' }"
            @click="mode = 'sign-in'"
          >
            Sign In
          </div>
          <div
            class="auth-modal__tab"
            :class="{
              'auth-modal__tab--selected': mode === 'registration',
            }"
            @click="mode = 'registration'"
          >
            Create An Account
          </div>
        </div>
        <div class="auth-modal__content">
          <template v-if="mode">
            <RegistrationForm v-if="registrationMode" @success="onSuccess" />
            <SignInForm v-else @success="onSuccess" />
          </template>
        </div>
      </div>
    </div>
  </AppModal>
</template>

<script>
import AppModal from '@/core/components/modals/AppModal'

import SignInForm from '@/auth/components/forms/SignInForm'
import RegistrationForm from '@/auth/components/forms/RegistrationForm'

export default {
  name: 'AuthModal',
  components: {
    AppModal,
    SignInForm,
    RegistrationForm,
  },
  data() {
    return {
      mode: '',
      closable: true,
      uploadingFiles: false,
      onSuccessLogin: null,
    }
  },
  computed: {
    registrationMode() {
      return this.mode === 'registration'
    },
  },
  methods: {
    onBeforeOpen(e) {
      this.closable = e.ref.params.value.closable
      this.mode = e.ref.params.value.mode || 'sign-in'

      if (e.ref.params.value.uploadingFiles) {
        this.uploadingFiles = true
        this.mode = 'registration'
      }
      if (e.ref.params.value.onSuccessLogin) {
        this.onSuccessLogin = e.ref.params.value.onSuccessLogin
      }
    },
    onClosed() {
      this.mode = ''
      this.closable = true
      this.uploadingFiles = false
    },
    onSuccess() {
      if (this.onSuccessLogin && typeof this.onSuccessLogin === 'function') {
        this.onSuccessLogin()
      }
    },
  },
}
</script>
