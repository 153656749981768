import { login, refreshToken } from '@/api/authApi'
import { fetchAccount, updateAccount, logoutAccount } from '@/api/accountApi'

import router from '@/core/router'

export default {
  async login({ commit }, payload) {
    const { data } = await login(payload)

    commit('AUTH_SUCCESS', data)

    commit('SET_ACCESS_TOKEN_LAST_UPDATE', Date.now())
    commit('SET_REFRESH_TOKEN_LAST_UPDATE', Date.now())

    commit('SET_USER', data)
  },
  async refresh({ commit, state }) {
    const { data } = await refreshToken(state.refreshToken)

    commit('AUTH_SUCCESS', data)

    commit('SET_ACCESS_TOKEN_LAST_UPDATE', Date.now())
    commit('SET_REFRESH_TOKEN_LAST_UPDATE', Date.now())

    return data.access
  },
  async fetchAccount({ commit }) {
    const { data } = await fetchAccount()

    commit('SET_USER', data)
  },
  async logout({ state, commit }) {
    try {
      if (state.accessToken && state.refreshToken) {
        await logoutAccount({
          accessToken: state.accessToken,
          refreshToken: state.refreshToken,
        })
      }
    } catch (err) {
      console.log(err)
    } finally {
      commit('AUTH_LOGOUT')
      await router.replace({ name: 'main' })
    }
  },
  async updateAccount({ state, commit }, payload) {
    if (!('firstName' in payload)) {
      payload['firstName'] = state.user.firstName
    }

    if (!('lastName' in payload)) {
      payload['lastName'] = state.user.lastName
    }

    if (!('email' in payload)) {
      payload['email'] = state.user.email
    }

    const { data } = await updateAccount(payload)

    commit('SET_USER', data)
  },
}
