<template>
  <form @submit.prevent="loginAttempt" class="auth-form">
    <transition name="fade" appear mode="out-in">
      <div v-if="loading" class="auth-form__loading">
        <AppSpinner />
      </div>
    </transition>
    <div class="auth-form__group">
      <div class="auth-form__item">
        <InputText
          v-model="email"
          placeholder="Email"
          :errors="validations.errors.email"
          :show-errors="submitted"
        />
      </div>
    </div>
    <div class="auth-form__group">
      <div class="auth-form__item">
        <InputText
          v-model="password"
          type="password"
          placeholder="Password"
          :errors="validations.errors.password"
          :show-errors="submitted"
        />
      </div>
    </div>
    <div class="auth-form__group">
      <div class="auth-form__item">
        <div class="u-text-left">
          <InputCheckbox v-model="rememberMe">
            Remember me
          </InputCheckbox>
        </div>
      </div>
      <div class="auth-form__item">
        <div class="u-text-right">
          <a
            href="#"
            class="link forgot-password"
            @click.prevent="showForgotPasswordModal"
            >Forgot Password</a
          >
        </div>
      </div>
    </div>
    <div class="auth-form__actions">
      <ButtonSubmit id="sqlogin">
        Sign In
      </ButtonSubmit>
    </div>
    <transition name="fade" appear mode="out-in">
      <div v-if="errorMessage" class="auth-form__error">
        <p class="paragraph paragraph--red">{{ errorMessage }}</p>
      </div>
    </transition>
  </form>
</template>

<script>
import moment from 'moment'
import { required, email } from '@/core/plugins/validator/validators'
import { getErrorMessage } from '@/core/helpers/error'

import InputText from '@/core/components/inputs/InputText'
import ButtonSubmit from '@/core/components/buttons/ButtonSubmit'
import AppSpinner from '@/core/components/content/AppSpinner'
import InputCheckbox from '@/core/components/inputs/InputCheckbox'

const validators = {
  email: [required(), email()],
  password: [required()],
}

export default {
  name: 'SignInForm',
  components: {
    InputCheckbox,
    InputText,
    ButtonSubmit,
    AppSpinner,
  },
  data() {
    return {
      email: '',
      password: '',
      rememberMe: false,

      errorMessage: '',
      submitted: false,
      loading: false,
    }
  },
  computed: {
    validations() {
      return this.$validator.validate(
        {
          email: this.email,
          password: this.password,
        },
        validators
      )
    },
  },
  methods: {
    async loginAttempt() {
      try {
        this.submitted = true

        if (!this.validations.isValid || this.loading) return

        this.loading = true

        await this.$store.dispatch('auth/login', {
          email: this.email,
          password: this.password,
          rememberMe: this.rememberMe,
        })
        this.$vfm.hide('auth')

        const user = this.$store.state.auth.user
        if (!user.passwordExpired || moment() > moment(user.passwordExpired)) {
          this.$vfm.show('force-reset-password')
        } else {
          this.$emit('success')
        }
      } catch (err) {
        setTimeout(() => {
          this.errorMessage = getErrorMessage(err)
        }, 100)
      } finally {
        setTimeout(() => {
          this.loading = false
        }, 100)
      }
    },
    showForgotPasswordModal() {
      this.$vfm.show('forgot-password')
    },
  },
}
</script>
