const StorageKeys = {
  ACCESS_TOKEN: 'auth:access_token',
  REFRESH_TOKEN: 'auth:refresh_token',
  ACCESS_TOKEN_LAST_UPDATE: 'auth:access_token_last_update',
  REFRESH_TOKEN_LAST_UPDATE: 'auth:refresh_token_last_update',
}

export const getAccessToken = () => {
  return localStorage.getItem(StorageKeys.ACCESS_TOKEN)
}

export const setAccessToken = token => {
  localStorage.setItem(StorageKeys.ACCESS_TOKEN, token)
}

export const getRefreshToken = () => {
  return localStorage.getItem(StorageKeys.REFRESH_TOKEN)
}

export const setRefreshToken = token => {
  localStorage.setItem(StorageKeys.REFRESH_TOKEN, token)
}

export const getAccessTokenLastUpdate = () => {
  const lastUpdate = localStorage.getItem(StorageKeys.ACCESS_TOKEN_LAST_UPDATE)

  if (lastUpdate) {
    return parseInt(lastUpdate)
  }

  return null
}

export const setAccessTokenLastUpdate = lastUpdate => {
  localStorage.setItem(StorageKeys.ACCESS_TOKEN_LAST_UPDATE, lastUpdate)
}

export const getRefreshTokenLastUpdate = () => {
  const lastUpdate = localStorage.getItem(StorageKeys.REFRESH_TOKEN_LAST_UPDATE)

  if (lastUpdate) {
    return parseInt(lastUpdate)
  }

  return null
}
export const setRefreshTokenLastUpdate = lastUpdate => {
  localStorage.setItem(StorageKeys.REFRESH_TOKEN_LAST_UPDATE, lastUpdate)
}

export const clearTokens = () => {
  localStorage.removeItem(StorageKeys.ACCESS_TOKEN)
  localStorage.removeItem(StorageKeys.REFRESH_TOKEN)

  localStorage.removeItem(StorageKeys.ACCESS_TOKEN_LAST_UPDATE)
  localStorage.removeItem(StorageKeys.REFRESH_TOKEN_LAST_UPDATE)
}
