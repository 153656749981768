<template>
  <AppModal
    name="force-reset-password"
    :small="true"
    :esc-to-close="true"
    @close="onClose"
  >
    <div class="u-flex-center u-margin-bottom-medium">
      <h2>Your password has expired, please create a new password</h2>
    </div>
    <ChangePasswordForm @changed="onChangedPassword" />
  </AppModal>
</template>

<script>
import AppModal from '@/core/components/modals/AppModal'
import ChangePasswordForm from '@/auth/components/forms/ChangePasswordForm'
export default {
  name: 'ForceResetPasswordModal',
  components: { ChangePasswordForm, AppModal },
  methods: {
    async onClose() {
      await this.$store.dispatch('auth/logout')
      this.$vfm.hide('force-reset-password')
    },
    onChangedPassword(success) {
      if (success) {
        this.$vfm.hide('force-reset-password')
      }
    },
  },
}
</script>
