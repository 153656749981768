<template>
  <AppModal
    name="bq-bulk-edit-parts"
    :small="true"
    :loading="loading"
    @closed="onClosed"
    @beforeOpen="onBeforeOpen"
    @close="$vfm.hide('bq-bulk-edit-parts')"
  >
    <div class="u-margin-bottom-small">
      <h1 class="page-title">
        Bulk Edit
      </h1>
    </div>
    <div class="part-bulk-edit">
      <div class="grid grid--column u-margin-bottom-small">
        <div class="grid__item">
          <InputSelect
            label="Technology"
            placeholder="Select Technology"
            :options="technologiesOptions"
            :small="true"
            :disabled="loading"
            v-model="part.technologyId"
          />
        </div>
        <div class="grid__item">
          <InputSelect
            label="Material"
            placeholder="Select Material"
            :options="materialsOptions"
            :small="true"
            :disabled="materialDisabled"
            v-model="part.materialId"
          />
        </div>
        <div class="grid__item">
          <InputSelect
            label="Finish"
            :placeholder="finishLabel"
            :options="finishesOptions"
            :small="true"
            :disabled="finishDisabled"
            v-model="part.finishId"
          />
        </div>
        <div class="grid__item">
          <InputText
            v-model="part.notes"
            label="Notes"
            :textarea="true"
            placeholder="Input Notes"
          />
        </div>
      </div>
      <div class="grid">
        <div class="grid__item">
          <InputQuantity
            label="Quantity"
            :disabled="loading"
            :max-value="maxQuantity"
            height="2.8rem"
            v-model="part.quantity"
          />
        </div>
      </div>
      <div class="part-bulk-edit__footer">
        <ButtonPrimary :disabled="loading" width="25rem" @click="apply">
          Apply
        </ButtonPrimary>
      </div>
    </div>
  </AppModal>
</template>

<script>
import { getErrorMessage } from '@/core/helpers/error'
import { PrintingTechnology } from '@/core/utils/constants'

import AppModal from '@/core/components/modals/AppModal'
import ButtonPrimary from '@/core/components/buttons/ButtonPrimary'
import InputSelect from '@/core/components/inputs/InputSelect'
import InputQuantity from '@/core/components/inputs/InputQuantity'
import InputText from '@/core/components/inputs/InputText'

export default {
  name: 'BQBulkEditPartsModal',
  components: {
    InputText,
    AppModal,
    ButtonPrimary,
    InputSelect,
    InputQuantity,
  },
  data() {
    return {
      part: {
        technologyId: null,
        materialId: null,
        finishId: null,
        quantity: 1,
        notes: '',
      },
      loading: false,
      errorMessage: '',
      printing: [1, 2, 3, 4, 8, 9, 11],
      technologies: [],
    }
  },
  computed: {
    maxQuantity() {
      return this.isInjectionMolding ? 100000 : 100
    },
    isPrintingTechnology() {
      return PrintingTechnology.includes(this.technologyId)
    },
    materialDisabled() {
      return this.loading || !this.selectedTechnology
    },
    finishDisabled() {
      return this.loading || !this.finishesOptions.length
    },
    selectedTechnology() {
      return this.technologies.find(
        technology => technology.id == this.part.technologyId
      )
    },
    selectedMaterial() {
      if (this.selectedTechnology && this.part.materialId) {
        return this.selectedTechnology.materials[
          this.part.materialId.toString()
        ]
      }

      return null
    },
    selectedFinish() {
      if (this.selectedTechnology && this.part.finishId) {
        return this.selectedTechnology.finishes[this.part.finishId.toString()]
      }

      return null
    },
    technologiesOptions() {
      return this.technologies.map(technology => ({
        title: technology.title,
        value: technology.id,
      }))
    },
    materialsOptions() {
      if (this.selectedTechnology) {
        const result = []
        Object.keys(this.selectedTechnology.materials).forEach(key => {
          const material = {
            title: this.selectedTechnology.materials[key.toString()].title,
            value: key,
          }
          result.push(material)
        })
        return result
      }

      return []
    },
    finishesOptions() {
      if (this.selectedTechnology) {
        const result = []
        Object.keys(this.selectedTechnology.finishes).forEach(key => {
          const finish = {
            title: this.selectedTechnology.finishes[key.toString()].title,
            value: key,
          }
          result.push(finish)
        })
        return result
      }

      return []
    },
    finishLabel() {
      return this.finishesOptions.length ? 'Select finish' : 'No options'
    },
  },
  watch: {
    'part.technologyId'() {
      this.part.materialId = null
      this.part.finishId = null
    },
  },
  methods: {
    onClosed() {
      this.part = {
        technologyId: null,
        materialId: null,
        finishId: null,
        quantity: 1,
        notes: '',
      }
      this.loading = false
    },
    async apply() {
      if (this.loading) return

      this.loading = true

      try {
        this.$emit('bulkEditParts', this.part)
        this.$vfm.hide('bq-bulk-edit-parts')
      } catch (err) {
        this.errorMessage = getErrorMessage(err)
      } finally {
        this.loading = false
      }
    },
    onBeforeOpen(e) {
      if ('technologies' in e.ref.params.value) {
        this.technologies = e.ref.params.value.technologies
      }
    },
  },
}
</script>
