<template>
  <div>
    <div class="grid u-margin-bottom-small">
      <div class="grid__item">
        <InputText
          label="First Name"
          placeholder="First Name"
          :small="true"
          :disabled="loading"
          :errors="validations.errors.firstName"
          :show-errors="submitted"
          v-model="form.firstName"
        />
      </div>
      <div class="grid__item">
        <InputText
          label="Last Name"
          placeholder="Last Name"
          :small="true"
          :disabled="loading"
          :errors="validations.errors.lastName"
          :show-errors="submitted"
          v-model="form.lastName"
        />
      </div>
    </div>
    <div class="grid u-margin-bottom-small">
      <div class="grid__item">
        <InputText
          label="Email address"
          placeholder="Email address"
          :small="true"
          :disabled="true"
          :errors="validations.errors.email"
          :show-errors="submitted"
          v-model="form.email"
        />
      </div>
      <div class="grid__item">
        <InputText
          :mask="phoneMask"
          label="Phone"
          placeholder="Phone(optional)"
          :small="true"
          :disabled="loading"
          :errors="validations.errors.phoneNumber"
          :show-errors="submitted"
          v-model="form.phoneNumber"
        />
      </div>
    </div>
    <div class="grid u-margin-bottom-medium">
      <div class="grid__item">
        <InputText
          label="Company Name"
          placeholder="Company Name"
          :small="true"
          :disabled="loading"
          v-model="form.company"
        />
      </div>
      <div class="grid__item">
        <InputText
          label="Zip Code"
          placeholder="Zip Code"
          :small="true"
          :disabled="loading"
          v-model="form.zipCode"
        />
      </div>
    </div>
    <div class="u-flex-center u-margin-bottom-medium" v-if="errorMessage">
      <span class="account-settings__error">
        {{ errorMessage }}
      </span>
    </div>
    <div class="u-flex-center u-margin-bottom-medium" v-if="successMessage">
      <span class="account-settings__success">
        {{ successMessage }}
      </span>
    </div>
    <div class="u-flex-center">
      <ButtonBase :disabled="loading" @click="submit">
        Save Changes
      </ButtonBase>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { required, email, phone } from '@/core/plugins/validator/validators'
import { PHONE_MASK } from '@/core/utils/masks'
import { getErrorMessage } from '@/core/helpers/error'

import InputText from '@/core/components/inputs/InputText'
import ButtonBase from '@/core/components/buttons/ButtonBase'

const validators = {
  email: [required(), email()],
  phoneNumber: [phone()],
  firstName: [required()],
  lastName: [required()],
}

export default {
  name: 'ProfileInformationForm',
  components: {
    InputText,
    ButtonBase,
  },
  data() {
    return {
      errorMessage: '',
      submitted: false,
      loading: false,
      successMessage: '',

      form: {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        company: '',
        zipCode: '',
      },

      phoneMask: PHONE_MASK,
    }
  },

  computed: {
    ...mapGetters('auth', ['user']),
    validations() {
      return this.$validator.validate(
        {
          phoneNumber: this.form.phoneNumber,
          email: this.form.email,
          firstName: this.form.firstName,
          lastName: this.form.lastName,
        },
        validators
      )
    },
  },
  created() {
    this.form.firstName = this.user.firstName || ''
    this.form.lastName = this.user.lastName || ''
    this.form.phoneNumber = this.user.phoneNumber || ''
    this.form.email = this.user.email || ''
    this.form.company = this.user.company || ''
    this.form.zipCode = this.user.zipCode || ''
  },
  methods: {
    async submit() {
      this.submitted = true
      this.successMessage = ''

      if (!this.validations.isValid || this.loading) return

      try {
        this.loading = true

        await this.$store.dispatch('auth/updateAccount', this.form)

        this.successMessage = 'Profile information has been saved'
        setTimeout(() => {
          this.successMessage = ''
        }, 5 * 1000)
      } catch (err) {
        this.errorMessage = getErrorMessage(err)
        setTimeout(() => {
          this.errorMessage = ''
        }, 5 * 1000)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
